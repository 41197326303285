import * as ValidationRegex from './ValidationRegex'

import CommonUtils from 'src/views/common/utils/Utils'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'
import { Yup } from 'cng-web-lib'
import moment from 'moment'

function ShipmentValidationSchema() {

  const validationMessageTranslation = ValidationMessageTranslationText()

  return Yup.object({


    moveType: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
    placeOfReceiptCode: Yup.string().nullable()
      .when("saveDraft", {
        is: false,
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage).nullable()
          .notOneOf(
            [Yup.ref('placeOfDeliveryCode')],
            validationMessageTranslation.PorPoDSameMsg
          ),
      })
      .test("placeOfDeliveryCode", validationMessageTranslation.PorPoDSameMsg, function (
        val
      ) {
        const { placeOfDeliveryCode } = this.parent;
        if (placeOfDeliveryCode && placeOfDeliveryCode == val) return val == null
        return true
      }),



    placeOfDeliveryCode: Yup.string().nullable()
      .when("saveDraft", {
        is: false,
        then: Yup.string()
          .required(validationMessageTranslation.requiredMessage).nullable()
          .notOneOf(
            [Yup.ref('placeOfReceiptCode')],
            validationMessageTranslation.PorPoDSameMsg
          ),
      })
      .when('placeOfReceiptCode', {
        is: value => value && value.length > 0,
        then: Yup.string().nullable()
          .notOneOf(
            [Yup.ref('placeOfReceiptCode')],
            validationMessageTranslation.PorPoDSameMsg
          ),
      }),


    placeOfReceipt: Yup.string().matches(ValidationRegex.excludeSpecialCharRegx, validationMessageTranslation.invalidStrMsg).nullable(),
    placeOfDelivery: Yup.string().matches(ValidationRegex.excludeSpecialCharRegx, validationMessageTranslation.invalidStrMsg).nullable(),
    destCode: Yup.string().matches(ValidationRegex.excludeSpecialCharRegx, validationMessageTranslation.invalidStrMsg).nullable(),
    finalDest: Yup.string().matches(ValidationRegex.excludeSpecialCharRegx, validationMessageTranslation.invalidStrMsg).nullable(),
    emptyPickupContactPhoneNo: Yup.string().matches(ValidationRegex.phoneRegx, validationMessageTranslation.invalidPhoneMsg).nullable(),
    shipFromContactPhoneNo: Yup.string().matches(ValidationRegex.phoneRegx, validationMessageTranslation.invalidPhoneMsg).nullable(),
    shipToContactPhoneNo: Yup.string().matches(ValidationRegex.phoneRegx, validationMessageTranslation.invalidPhoneMsg).nullable(),


    cargoReadyDate: Yup.date()
      //.validFormat('DD-MMM-YYYY')
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTypeErrorMsg)
      .when("amendBooking", {
        is: true,
        then: Yup.date().required(validationMessageTranslation.requiredMessage).nullable(),
        otherwise: Yup.date()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTypeErrorMsg)
        //.min(moment().format('DD-MMM-YYYY'), validationMessageTranslation.CargoReadyDateBeforeTodayMsg)
      }),




    cargoDeliveryDate: Yup.date()
      //.validFormat('DD-MMM-YYYY')
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTypeErrorMsg)
      .when("amendBooking", {
        is: true,
        then: Yup.date().required(validationMessageTranslation.requiredMessage),
        otherwise: Yup.date()
          .required(validationMessageTranslation.requiredMessage)
          .nullable()
          .when("podEta", {
            is: null,
            then: Yup.date().min(Yup.ref('cargoReadyDate'), validationMessageTranslation.CargoDeliveryDateBeforeCargoReadyDateMsg),
            //otherwise: Yup.date().min(Yup.ref('podEta'), validationMessageTranslation.CargoDeliveryDateBeforePODETAMsg),
          })
      }),


    placeOfReceiptEtd: Yup.date()
      // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
      .when("amendBooking", {
        is: false,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .min(moment(moment().format(CommonUtils.UI_FORMAT_DATE)).format(CommonUtils.UI_FORMAT_DATE_TIME), validationMessageTranslation.PoRETDBeforeTodayMsg)
      })
      .when("amendBooking", {
        is: true,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .required(validationMessageTranslation.requiredMessage).nullable(),
      }),


    placeOfDeliveryEta: Yup.date()
      // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
      .nullable()
      .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
      .when("amendBooking", {
        is: false,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .min(moment(moment().format(CommonUtils.UI_FORMAT_DATE)).format(CommonUtils.UI_FORMAT_DATE_TIME), "place of delivery Eta can't be before today."),
      })
      .when("amendBooking", {
        is: true,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg)
          .required(validationMessageTranslation.requiredMessage).nullable(),
      }),
    // .when("amendBooking", {
    //   is: true,
    //   then: Yup.date().required(validationMessageTranslation.requiredMessage).nullable()
    //     .min(moment(moment().format(CommonUtils.UI_FORMAT_DATE)).format(CommonUtils.UI_FORMAT_DATE_TIME), "place of delivery Eta can't be before today."),
    //   otherwise: Yup.date()
    //     .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
    //     .nullable()
    //     .typeError(validationMessageTranslation.dateTypeErrorMessage),
    //   // .min(moment(moment().format(CommonUtils.UI_FORMAT_DATE)).format(CommonUtils.UI_FORMAT_DATE_TIME), validationMessageTranslation.PoRETDBeforeTodayMsg)
    // }),
    // placeOfDeliveryEta: null, //no validation for the two dates


    emptyPickupCompName: Yup.string().nullable().matches(ValidationRegex.spaceNotAllowRegx, validationMessageTranslation.invalidBlankSpaceMsg)
      .when(['isEmptyPickupFilled', 'showEmptyPickup'], {
        is: true,
        then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
        otherwise: Yup.string().nullable()
      }),

    emptyPickupCompAddress: Yup.string().matches(ValidationRegex.addressMaxRegx, validationMessageTranslation.invalidAddressMsg)
      .matches(ValidationRegex.spaceNotAllowRegx, validationMessageTranslation.invalidBlankSpaceMsg)
      .when(['isEmptyPickupFilled', 'showEmptyPickup'], {
        is: true,
        then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
        otherwise: Yup.string().nullable()
      }),


    emptyPickupDatePickup: Yup.date().nullable()
      .when(['isEmptyPickupFilled', 'showEmptyPickup'], {
        is: true,
        then: Yup.date()
          .nullable()
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),

      }),

    emptyDropoffCompName: Yup.string().nullable()
      .when(['isEmptyDropoffFilled', 'showEmptyDropoff'], {
        is: true,
        then: Yup.string().required(validationMessageTranslation.requiredMessage)
          .matches(ValidationRegex.spaceNotAllowRegx, validationMessageTranslation.invalidBlankSpaceMsg)
          .nullable(),
        otherwise: Yup.string().nullable()
      }),

    emptyDropoffCompAddress: Yup.string()
      .matches(ValidationRegex.addressMaxRegx, validationMessageTranslation.invalidAddressMsg)
      .when(['isEmptyDropoffFilled', 'showEmptyDropoff'], {
        is: true,
        then: Yup.string().required(validationMessageTranslation.requiredMessage)
          .matches(ValidationRegex.spaceNotAllowRegx, validationMessageTranslation.invalidBlankSpaceMsg)
          .nullable(),
        otherwise: Yup.string().nullable()
      }),


    emptyDropoffDateDropoff: Yup.date().nullable()
      .when(['isEmptyDropoffFilled', 'showEmptyDropoff'], {
        is: true,
        then: Yup.date()
          .nullable()
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),

      }),



    shipToCompName: Yup.string().when("showShipTo", {
      is: true,
      then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),
    shipToCompAddress: Yup.string().when("showShipTo", {
      is: true,
      then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
        .matches(ValidationRegex.addressMaxRegx, validationMessageTranslation.invalidAddressMsg)
        .matches(ValidationRegex.spaceNotAllowRegx, validationMessageTranslation.invalidBlankSpaceMsg),
    }),

    shipToDateDelivery: Yup.date().nullable()
      .when("showShipTo", {
        is: true,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),
      }),


    shipFromCompName: Yup.string().when("showShipFrom", {
      is: true,
      then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
      otherwise: Yup.string().nullable()
    }),
    shipFromCompAddress: Yup.string()
      .matches(ValidationRegex.addressMaxRegx, validationMessageTranslation.invalidAddressMsg)
      .matches(ValidationRegex.spaceNotAllowRegx, validationMessageTranslation.invalidBlankSpaceMsg)
      .when("showShipFrom", {
        is: true,
        then: Yup.string().required(validationMessageTranslation.requiredMessage).nullable(),
        otherwise: Yup.string().nullable(),
      }),

    shipFromDatePickup: Yup.date().nullable()
      .when("showShipFrom", {
        is: true,
        then: Yup.date()
          .nullable()
          // .transform((curr, orig) => orig === '' ? null : curr)
          // .validFormat(CommonUtils.UI_FORMAT_DATE_TIME)
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),
      }),


    shipFromDatePositioning: Yup.date().nullable()
      .when("showShipFrom", {
        is: true,
        then: Yup.date()
          .nullable()
          .typeError(validationMessageTranslation.FrbDateTimeTypeErrorMsg),
      }),
  })
}

export default ShipmentValidationSchema


